export const config = {
  email: {
    minlength: 2,
    maxlength: 100,
    patternValidate: /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i
  },
  password: {
    minlength: 6,
    maxlength: 15,
    patternValidate: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,15}/
  },
  name: {
    maxlength: 100,
  },
  lastname: {
    maxlength: 100,
  },
  defaultPatternValidate: {
    oneDecimalPattern: /^[0-9]+([.][0-9]{1})?$/,
    twoDecimalPattern: /^[0-9]+([.][0-9]{1,2})?$/
  }
};
