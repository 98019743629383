import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IHttpResponse } from '../interfaces/htttp-response.interface';
import { IRole } from '../interfaces/role.interface';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
  ) { }

  public getDashboard(): Promise<any> {
    return this.http.get(`${environment.API_URL}${environment.API_V1}proyect/dashboard`).toPromise();
  }

  public getUserList(): Promise<any> {
    return this.http.get(`${environment.API_URL}${environment.API_V1}user`).toPromise();
  }

  public addUser(body: any): Promise<any> {
    return this.http.post(`${environment.API_URL}${environment.API_V1}user`, body).toPromise();
  }

  public disableUser(userID: string): Promise<any> {
    return this.http.patch(`${environment.API_URL}${environment.API_V1}user/${userID}`, null).toPromise();
  }

  public deleteUser(userID: string): Promise<any> {
    return this.http.delete(`${environment.API_URL}${environment.API_V1}user/${userID}` ).toPromise();
  }


  public getRole(): IRole[] {
    return [
      {
        name: 'Administrador',
        code: 'Administrador',
      },
      {
        name: 'Ejecutivo',
        code: 'Ejecutivo',
      }
    ];
  }
}
